// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users_pagination_icons__ACmuo>div>div:nth-child(5) {
    display: flex !important;
}

.Users_pagination_icons__ACmuo>div>div:nth-child(5)>button {
    width: auto;
}

.Users_user_input__xbr4B > div > input {
    padding: 8px;
}

.Users_users_table__4vlI5>thead>tr>th {
    font-size: 18px;
    font-weight: 500;
    background-color: #0f3165;
    color: white;
}

.Users_active_status__\\+vgrt {
    color: #1f821f;
    text-transform: lowercase;
    padding: 5px;
    border: 1px solid #1f821f;
    border-radius: 10%;
}

.Users_inactive_status__U01CF {
    color: #951e1e;
    text-transform: lowercase;
    padding: 5px;
    border: 1px solid #951e1e;
    border-radius: 10%;
}

.Users_body_rows__nZlqC > td > label {
    display: none !important;
    
}

@media only screen and (max-width: 450px) {
    .Users_main_header__LZVXu > tr {
        display: none;
    }
    .Users_body_rows__nZlqC {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 1rem;
        box-shadow:
                0 0 0 2px white,
                0.3em 0.3em 1em #23000099;
    }
    .Users_body_rows__nZlqC>td {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
    .Users_body_rows__nZlqC>td>label {
        display: block !important;
        font-weight: 600 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/users/Users.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;;AAE5B;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,wBAAwB;QACxB,iCAAiC;QACjC,mBAAmB;QACnB;;yCAEiC;IACrC;IACA;QACI,wBAAwB;QACxB,yCAAyC;QACzC,8BAA8B;IAClC;IACA;QACI,yBAAyB;QACzB,2BAA2B;IAC/B;AACJ","sourcesContent":[".pagination_icons>div>div:nth-child(5) {\n    display: flex !important;\n}\n\n.pagination_icons>div>div:nth-child(5)>button {\n    width: auto;\n}\n\n.user_input > div > input {\n    padding: 8px;\n}\n\n.users_table>thead>tr>th {\n    font-size: 18px;\n    font-weight: 500;\n    background-color: #0f3165;\n    color: white;\n}\n\n.active_status {\n    color: #1f821f;\n    text-transform: lowercase;\n    padding: 5px;\n    border: 1px solid #1f821f;\n    border-radius: 10%;\n}\n\n.inactive_status {\n    color: #951e1e;\n    text-transform: lowercase;\n    padding: 5px;\n    border: 1px solid #951e1e;\n    border-radius: 10%;\n}\n\n.body_rows > td > label {\n    display: none !important;\n    \n}\n\n@media only screen and (max-width: 450px) {\n    .main_header > tr {\n        display: none;\n    }\n    .body_rows {\n        display: flex !important;\n        flex-direction: column !important;\n        margin-bottom: 1rem;\n        box-shadow:\n                0 0 0 2px white,\n                0.3em 0.3em 1em #23000099;\n    }\n    .body_rows>td {\n        display: flex !important;\n        justify-content: space-between !important;\n        align-items: center !important;\n    }\n    .body_rows>td>label {\n        display: block !important;\n        font-weight: 600 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination_icons": `Users_pagination_icons__ACmuo`,
	"user_input": `Users_user_input__xbr4B`,
	"users_table": `Users_users_table__4vlI5`,
	"active_status": `Users_active_status__+vgrt`,
	"inactive_status": `Users_inactive_status__U01CF`,
	"body_rows": `Users_body_rows__nZlqC`,
	"main_header": `Users_main_header__LZVXu`
};
export default ___CSS_LOADER_EXPORT___;
