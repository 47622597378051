import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import DraftsIcon from "@mui/icons-material/Drafts";
import MarkunreadIcon from "@mui/icons-material/Markunread";

const MessageCount = ({ messageType, value }) => {
  return (
    <Box maxWidth={"350px"}>
      <Paper elevation={3}>
        <Box padding={"1rem"} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1 }}>
            {messageType === "Sent" ? (
              <SendIcon sx={{ color: "#06284b", fontSize: 40 }} />
            ) : messageType === "Unsent" ? (
              <CancelScheduleSendIcon sx={{ color: "#06284b", fontSize: 40 }} />
            ) : messageType === "Seen" ? (
              <DraftsIcon sx={{ color: "#06284b", fontSize: 40 }} />
            ) : (
              <MarkunreadIcon sx={{ color: "#06284b", fontSize: 40 }} />
            )}
          </Box>
          <Box sx={{ flex: 3 }}>
            <Typography fontWeight={"600"}>Total {messageType}</Typography>
            <Typography fontSize={"24px"}>{value}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default MessageCount;
