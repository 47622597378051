import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AlertSnackbar from "./AlertSnackbar";

const UnsubscribePage = () => {
  const { contactId } = useParams();
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "",
    message: "",
  });

  const showSnackbar = ({ severity, message }) => {
    setOpenSnackbar(true);
    setSnackbarConfig({ severity, message });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarConfig({ severity: "", message: "" });
  };

  const handleUnsubscribe = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}/core/contacts/unsubscribe?contactId=${contactId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setIsUnsubscribed(true);
        // showSnackbar({
        //   severity: "success",
        //   message: "Unsubscribe successfully",
        // });
      }
    } catch (error) {
      showSnackbar({
        severity: "error",
        message: "Failed to unsubscribe",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgb(242, 248, 251)",
      }}
    >
      <Box sx={{ marginTop: "3rem" }}>
        {isUnsubscribed === false ? (
          <Card sx={{ minWidth: 275, padding: "2rem" }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Unsubscribe from our mailing list?
              </Typography>
              <Typography variant="body2" mt={1}>
                Are you sure you don't want to be a part of the mailing list
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={handleUnsubscribe} variant="outlined">
                Yes, I confirm
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Card sx={{ minWidth: 275, padding: "2rem" }}>
            <CardContent>
              <Typography variant="h4" component="div">
                Successfully unsubscribed from our mailing list
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
      <AlertSnackbar
        isOpen={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={snackbarConfig.message}
        severity={snackbarConfig.severity}
      ></AlertSnackbar>
    </Box>
  );
};

export default UnsubscribePage;
