import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import styles from "./Sender.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SenderForm = ({ open, onClose, onSave, sender, onUpdate }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const inputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData?.mail) {
      newErrors.mail = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.mail)) {
      newErrors.mail = "Invalid email address";
    }

    if (!formData?.passKey) {
      newErrors.passKey = "Password is required";
    }

    if(!formData?.host) {
      newErrors.host = "Host is required";
    }

    if (!formData?.port) {
      newErrors.port = "Port is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = () => {
    onUpdate(formData);
    onClose();
    setFormData({});
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(formData);
      onClose();
      setFormData({});
    }
  };

  const handleWheel = (e) => {
    inputRef.current.blur();
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    setFormData(
      sender || { mail: "", passKey: "", host: "smtp.gmail.com", port: "465" }
    );
    setErrors({})
  }, [sender]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{sender ? "Edit Sender" : "Add Sender"}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <InputLabel htmlFor="mail">
            Email<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            id="mail"
            name="mail"
            type="email"
            value={formData?.mail}
            onChange={(e) => handleChange("mail", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors.mail}
            helperText={errors.mail}
            className={styles.email_input}
          />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="host">
            Host<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            id="host"
            name="host"
            value={formData?.host}
            onChange={(e) => handleChange("host", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors.host}
            helperText={errors.host}
            className={styles.email_input}
          />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="port">
            Port<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            id="port"
            name="port"
            type="number"
            value={formData?.port}
            onChange={(e) => handleChange("port", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors.port}
            helperText={errors.port}
            className={styles.email_input}
            inputRef={inputRef}
            onWheel={handleWheel}
          />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="passKey">
            Password<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            id="passKey"
            name="passKey"
            type={showPassword ? "text" : "password"}
            value={formData?.passKey}
            onChange={(e) => handleChange("passKey", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors.passKey}
            helperText={errors.passKey}
            className={styles.email_input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "50%",
            gap: "10px",
          }}
        >
          {sender ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              sx={{ backgroundColor: "#0f3165" }}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ backgroundColor: "#0f3165" }}
            >
              Save
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{ color: "#0f3165", borderColor: "#0f3165" }}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SenderForm;
