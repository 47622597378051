import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HtmlFileUploader from "./HtmlFileUploader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./Template.module.css";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AlertSnackbar from "../../components/AlertSnackbar";

const TemplateFormPage = () => {
  const [formData, setFormData] = useState({ name: "", content: "" });
  const [errors, setErrors] = useState({});
  const [index, setIndex] = useState("0");
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    severity: "",
    message: "",
  });
  const { templateId } = useParams();

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const handleHtmlContentChange = (content) => {
    setFormData((prevData) => ({ ...prevData, content }));
  };

  const handleGetTemplateData = async (templateId) => {
    const storedToken = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}/core/template/${templateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setFormData({ name: data.name, content: data.content });
        console.log(data);
        // setTemplates(data);
      } else {
        console.log("Role failed");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleCreateTemplate = async (template) => {
    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}/core/template/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(template),
        }
      );
      if (response) {
        // const data = await response.json();
        // await handleGetAllTemplates();
        showSnackbar({
          severity: "success",
          message: "Template created successfully",
        });
        setTimeout(() => {
          navigateBack();
        }, 500);
      } else {
        console.error("Failed to create template");
        showSnackbar({
          severity: "error",
          message: "Failed to create template",
        });
      }
    } catch (error) {
      showSnackbar({
        severity: "error",
        message: "Failed to create template",
      });
    }
  };

  const handleUpdateTemplateData = async (template) => {
    try {
      const storedToken = localStorage.getItem("authToken");
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}/core/template/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(template),
        }
      );

      if (response.ok) {
        // await handleGetAllTemplates();
        showSnackbar({
          severity: "success",
          message: "Template updated successfully",
        });
        setTimeout(() => {
          navigateBack();
        }, 500);
      } else {
        console.error("Failed to update");
        showSnackbar({
          severity: "error",
          message: "Failed to update template",
        });
      }
    } catch (error) {
      console.error("Error Updating template:", error);
      showSnackbar({
        severity: "error",
        message: "Failed to update template",
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData?.name) {
      newErrors.name = "Name is required";
    }

    if (!formData?.content) {
      newErrors.content = "Content is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleEditorChange = (content, delta, source, editor) => {
    handleChange("content", content);
  };

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["code-block"],
    ],
  };

  const quillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align",
    "code-block",
  ];

  const navigateBack = () => {
    navigate(-1);
  };

  const handleSubmitForm = () => {
    if (validateForm()) {
      if (templateId) {
        const updateData = {
          templateId: templateId,
          content: formData.content,
        };
        handleUpdateTemplateData(updateData);
      } else {
        handleCreateTemplate(formData);
      }
    }
  };

  const showSnackbar = ({ severity, message }) => {
    setOpenSnackbar(true);
    setSnackbarConfig({ severity, message });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setSnackbarConfig({ severity: "", message: "" });
  };

  useEffect(() => {
    if (templateId) {
      handleGetTemplateData(templateId);
    } else {
      setFormData({ name: "", content: "" });
      setErrors({});
    }
  }, [templateId]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "start", gap: "10px" }}>
        <IconButton
          sx={{ width: "auto", border: "1px solid" }}
          onClick={navigateBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          {templateId ? "Update" : "Create"} Template
        </Typography>
      </Box>
      <Box>
        <Paper sx={{ padding: "2rem", marginTop: "1rem" }}>
          <form onSubmit={handleSubmitForm}>
            <Box mb={2}>
              <InputLabel htmlFor="name">
                Name<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                id="name"
                name="name"
                value={formData?.name}
                onChange={(e) => handleChange("name", e.target.value)}
                fullWidth
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
                disabled={templateId}
                className={styles.template_input}
              />
            </Box>
            <Box mb={2}>
              <TabContext value={index.toString()}>
                <Tabs
                  value={index}
                  aria-label="Segmented Tabs"
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  onChange={(event, value) => setIndex(value)}
                >
                  <TabList
                    disableUnderline
                    variant="plain"
                    className={styles.tab_list}
                  >
                    <Tab
                      label="Upload file"
                      value="0"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#393943",
                          borderRadius: "25px",
                          margin: "5px",
                          color: "#fff",
                        },
                      }}
                    />
                    <Tab
                      label="Enter text"
                      value="1"
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#393943",
                          borderRadius: "25px",
                          margin: "5px",
                          color: "#fff",
                        },
                      }}
                    />
                  </TabList>
                </Tabs>
                <TabPanel value="0" sx={{ padding: "0", marginTop: "8px" }}>
                  <HtmlFileUploader
                    htmlFileContent={handleHtmlContentChange}
                    template={formData.content}
                  />
                </TabPanel>
                <TabPanel value="1" sx={{ padding: "0", marginTop: "8px" }}>
                  <Box mb={2}>
                    <InputLabel htmlFor="content">
                      Content<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <ReactQuill
                      theme="snow"
                      value={formData.content ? formData.content : ""}
                      onChange={handleEditorChange}
                      modules={quillModules}
                      formats={quillFormats}
                      style={{ height: "400px", marginBottom: "1rem" }}
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              gap: "10px",
              marginTop: "4rem",
            }}
          >
            {templateId ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmitForm}
                sx={{ backgroundColor: "#0f3165" }}
              >
                Update
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmitForm}
                sx={{ backgroundColor: "#0f3165" }}
              >
                Save
              </Button>
            )}

            <Button
              variant="outlined"
              color="primary"
              onClick={navigateBack}
              sx={{ color: "#0f3165", borderColor: "#0f3165" }}
            >
              Cancel
            </Button>
          </Box>
        </Paper>
      </Box>
      <AlertSnackbar
        isOpen={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={snackbarConfig.message}
        severity={snackbarConfig.severity}
      ></AlertSnackbar>
    </Box>
  );
};

export default TemplateFormPage;
