// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar_list__jWJ7z > div {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Sidebar_activeLink__duTIX {
    background-color: #2196f3;
    color: white;
}

.Sidebar_icon_color__VlSiO {
    color: #195aad;
}`, "",{"version":3,"sources":["webpack://./src/css/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".sidebar_list > div {\n    margin-top: 64px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.activeLink {\n    background-color: #2196f3;\n    color: white;\n}\n\n.icon_color {\n    color: #195aad;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar_list": `Sidebar_sidebar_list__jWJ7z`,
	"activeLink": `Sidebar_activeLink__duTIX`,
	"icon_color": `Sidebar_icon_color__VlSiO`
};
export default ___CSS_LOADER_EXPORT___;
