// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_profile_input__FtvqC > div >input {
    padding: 10px;
}

.Profile_button_clr__0jfv0 {
    background-color: #0f3165 !important;
}

.Profile_button_clr__0jfv0:disabled {
    color: #fff !important;
    background-color: #ccc !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,iCAAiC;AACrC","sourcesContent":[".profile_input > div >input {\n    padding: 10px;\n}\n\n.button_clr {\n    background-color: #0f3165 !important;\n}\n\n.button_clr:disabled {\n    color: #fff !important;\n    background-color: #ccc !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_input": `Profile_profile_input__FtvqC`,
	"button_clr": `Profile_button_clr__0jfv0`
};
export default ___CSS_LOADER_EXPORT___;
