// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Login_login_container__qBBWX {
  text-align: center;
}

.Login_login_container__qBBWX > h2 {
  text-transform: uppercase;
}

.Login_login_form__\\+BhzK {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}

.Login_login_input__R2b-m {
  margin: 5px;
  padding: 10px;
}

.Login_login_button__2zIRN {
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #0f3165;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/css/Login.module.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["\n.login_container {\n  text-align: center;\n}\n\n.login_container > h2 {\n  text-transform: uppercase;\n}\n\n.login_form {\n  display: flex;\n  flex-direction: column;\n  width: 300px;\n  margin: 0 auto;\n}\n\n.login_input {\n  margin: 5px;\n  padding: 10px;\n}\n\n.login_button {\n  margin: 10px;\n  padding: 10px;\n  cursor: pointer;\n  border: none;\n  background-color: #0f3165;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login_container": `Login_login_container__qBBWX`,
	"login_form": `Login_login_form__+BhzK`,
	"login_input": `Login_login_input__R2b-m`,
	"login_button": `Login_login_button__2zIRN`
};
export default ___CSS_LOADER_EXPORT___;
