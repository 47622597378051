// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Campaigns_pagination_icons__fUHSi>div>div:nth-child(5) {
    display: flex !important;
}

.Campaigns_pagination_icons__fUHSi>div>div:nth-child(5)>button {
    width: auto;
}

.Campaigns_campaigns_table__VUOwq>thead>tr>th {
    font-size: 18px;
    font-weight: 500;
    background-color: #0f3165;
    color: white;
}

.Campaigns_select_input__WD47- > div:first-child {
    padding: 8px;
}

.Campaigns_campaign_date__WviaA > div:first-child > input {
    padding: 8px;
}

.Campaigns_campaign_input__6IGqP > div > input {
    padding: 8px;
}

.Campaigns_campaign_select_group__ndfCT > div > div:first-child {
    padding: 0px;
}

.Campaigns_campaign_table__R6fb4 > thead > tr > th {
    font-size: 16px;
    font-weight: 500;
    background-color: #8f9dad;
}

.Campaigns_body_rows__wLv0a>td>label {
    display: none !important;
}

@media only screen and (max-width: 450px) {
    .Campaigns_main_header__Jwkhg>tr {
        display: none;
    }

    .Campaigns_body_rows__wLv0a {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 1rem;
        box-shadow:
            0 0 0 2px white,
            0.3em 0.3em 1em #23000099;
    }

    .Campaigns_body_rows__wLv0a>td {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }

    .Campaigns_body_rows__wLv0a>td>label {
        display: block !important;
        font-weight: 600 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/campaign/Campaigns.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,wBAAwB;QACxB,iCAAiC;QACjC,mBAAmB;QACnB;;qCAE6B;IACjC;;IAEA;QACI,wBAAwB;QACxB,yCAAyC;QACzC,8BAA8B;IAClC;;IAEA;QACI,yBAAyB;QACzB,2BAA2B;IAC/B;AACJ","sourcesContent":[".pagination_icons>div>div:nth-child(5) {\n    display: flex !important;\n}\n\n.pagination_icons>div>div:nth-child(5)>button {\n    width: auto;\n}\n\n.campaigns_table>thead>tr>th {\n    font-size: 18px;\n    font-weight: 500;\n    background-color: #0f3165;\n    color: white;\n}\n\n.select_input > div:first-child {\n    padding: 8px;\n}\n\n.campaign_date > div:first-child > input {\n    padding: 8px;\n}\n\n.campaign_input > div > input {\n    padding: 8px;\n}\n\n.campaign_select_group > div > div:first-child {\n    padding: 0px;\n}\n\n.campaign_table > thead > tr > th {\n    font-size: 16px;\n    font-weight: 500;\n    background-color: #8f9dad;\n}\n\n.body_rows>td>label {\n    display: none !important;\n}\n\n@media only screen and (max-width: 450px) {\n    .main_header>tr {\n        display: none;\n    }\n\n    .body_rows {\n        display: flex !important;\n        flex-direction: column !important;\n        margin-bottom: 1rem;\n        box-shadow:\n            0 0 0 2px white,\n            0.3em 0.3em 1em #23000099;\n    }\n\n    .body_rows>td {\n        display: flex !important;\n        justify-content: space-between !important;\n        align-items: center !important;\n    }\n\n    .body_rows>td>label {\n        display: block !important;\n        font-weight: 600 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination_icons": `Campaigns_pagination_icons__fUHSi`,
	"campaigns_table": `Campaigns_campaigns_table__VUOwq`,
	"select_input": `Campaigns_select_input__WD47-`,
	"campaign_date": `Campaigns_campaign_date__WviaA`,
	"campaign_input": `Campaigns_campaign_input__6IGqP`,
	"campaign_select_group": `Campaigns_campaign_select_group__ndfCT`,
	"campaign_table": `Campaigns_campaign_table__R6fb4`,
	"body_rows": `Campaigns_body_rows__wLv0a`,
	"main_header": `Campaigns_main_header__Jwkhg`
};
export default ___CSS_LOADER_EXPORT___;
