// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sender_pagination_icons__b57Bb>div>div:nth-child(5) {
    display: flex !important;
}

.Sender_pagination_icons__b57Bb>div>div:nth-child(5)>button {
    width: auto;
}

.Sender_email_input__QfIMT>div>input {
    padding: 8px;
}

.Sender_senders_table__wUiuX {
    /* margin-top: 10px; */
    box-shadow: 5px 5px 8px #555;
}

.Sender_senders_table__wUiuX>thead>tr>th {
    font-size: 18px;
    font-weight: 500;
    background-color: #0f3165;
    color: white;
}

.Sender_body_rows__p9\\+r3>td>label {
    display: none !important;
}

@media only screen and (max-width: 450px) {
    .Sender_main_header__ugFQf>tr {
        display: none;
    }

    .Sender_body_rows__p9\\+r3 {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 1rem;
        box-shadow:
            0 0 0 2px white,
            0.3em 0.3em 1em #23000099;
    }

    .Sender_body_rows__p9\\+r3>td {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }

    .Sender_body_rows__p9\\+r3>td>label {
        display: block !important;
        font-weight: 600 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/sender/Sender.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,wBAAwB;QACxB,iCAAiC;QACjC,mBAAmB;QACnB;;qCAE6B;IACjC;;IAEA;QACI,wBAAwB;QACxB,yCAAyC;QACzC,8BAA8B;IAClC;;IAEA;QACI,yBAAyB;QACzB,2BAA2B;IAC/B;AACJ","sourcesContent":[".pagination_icons>div>div:nth-child(5) {\n    display: flex !important;\n}\n\n.pagination_icons>div>div:nth-child(5)>button {\n    width: auto;\n}\n\n.email_input>div>input {\n    padding: 8px;\n}\n\n.senders_table {\n    /* margin-top: 10px; */\n    box-shadow: 5px 5px 8px #555;\n}\n\n.senders_table>thead>tr>th {\n    font-size: 18px;\n    font-weight: 500;\n    background-color: #0f3165;\n    color: white;\n}\n\n.body_rows>td>label {\n    display: none !important;\n}\n\n@media only screen and (max-width: 450px) {\n    .main_header>tr {\n        display: none;\n    }\n\n    .body_rows {\n        display: flex !important;\n        flex-direction: column !important;\n        margin-bottom: 1rem;\n        box-shadow:\n            0 0 0 2px white,\n            0.3em 0.3em 1em #23000099;\n    }\n\n    .body_rows>td {\n        display: flex !important;\n        justify-content: space-between !important;\n        align-items: center !important;\n    }\n\n    .body_rows>td>label {\n        display: block !important;\n        font-weight: 600 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination_icons": `Sender_pagination_icons__b57Bb`,
	"email_input": `Sender_email_input__QfIMT`,
	"senders_table": `Sender_senders_table__wUiuX`,
	"body_rows": `Sender_body_rows__p9+r3`,
	"main_header": `Sender_main_header__ugFQf`
};
export default ___CSS_LOADER_EXPORT___;
