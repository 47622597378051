// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Template_template_input__1S6c1 > div > input {
    padding: 8px;
}

.Template_tab_list__XWbIJ {
    background-color: #ededed;
    border-radius: 22px;
}

.Template_tab_list__XWbIJ > div:first-child > span {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/templates/Template.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".template_input > div > input {\n    padding: 8px;\n}\n\n.tab_list {\n    background-color: #ededed;\n    border-radius: 22px;\n}\n\n.tab_list > div:first-child > span {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template_input": `Template_template_input__1S6c1`,
	"tab_list": `Template_tab_list__XWbIJ`
};
export default ___CSS_LOADER_EXPORT___;
